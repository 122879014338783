.App {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.contentFrame{
  width: 100%;
}
.hamburger{
  position: absolute;
  top:0px;
  left: 0px;
  z-index: 100;
  padding: 5px;
  display: none;
}


