.container{
    width: 100%;
    background-color: #f5f5f5;
    background-image: url("../images/main_bkg.png");
    background-size: 1000px 1000px;
    background-repeat: no-repeat;
    background-position: top right;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-left: 20px;
}
.container a{
    color: black;
}
.container a:visited{
    color: black;
}
.container a:active{
     color: black;
 }

.headerText{
    font-family: monospace;
}

.borderedSpan{
    border: 1px solid #000;
    padding:15px;
}
.iconLabelMod{
    padding-right: 55px;
}
.iconMod{
    right: 30px;
    bottom: 8px;
}
.alignedRight{
    text-align: right;
}
.show{
    display: block;
}
.hide{
    display: none;
}
.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 2s;
}

@-webkit-keyframes fade {
    from {opacity: 0}
    to {opacity: 1}
}

@keyframes fade {
    from {opacity: 0}
    to {opacity: 1}
}
@media only screen and (max-width: 760px) {
    .container{
        background-size: 500px 500px;
        align-items: flex-end;
        padding-bottom: 150px;
    }
}
