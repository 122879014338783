.container{
    padding: 15px;
    width: 100%;
    background-color: #f5f5f5;
}
.blueTimelineItem{
    border-top: 5px solid #00c4ff;
}
.blueTimelineIcon{
     background-color: #00c4ff;
 }

.greenTimelineItem{
    border-top: 5px solid #10cc52;
}
.greenTimelineIcon{
    background-color: #10cc52;
    color: white;
}

.timeline{
    background-color: #f5f5f5;
}

.timelineDate{
    border-radius: 20px;
    padding: 10px !important;
    text-align: center !important;
    max-width: 150px;
}
.greenTimelineDate{
    background-color: #dcffd5;
    border: 1px solid #bff5b5;
}
.blueTimelineDate{
    background-color: #d3eff8;
    border: 1px solid #bfe3ee;
}
