.hamburgerContainer{
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    display: none;
}

.hamburgerContainer div{
    width: 25px;
    height: 5px;
    background-color: #2d2d2d;
    border-radius: 3px;
    margin: 3px;
    transition:transform 0.5s, visibility 0.5s ease-in;
}
.hamburgerContainer.open .div1{
    transform: translate(0px, 8px) rotate(45deg);
}
.hamburgerContainer.open .div2{
    transform: translate(-28px, 0px);
    visibility: hidden;
}
.hamburgerContainer.open .div3{
    transform: translate(0px, -8px) rotate(-45deg);
}
@media only screen and (max-width: 760px) {
    .hamburgerContainer{
        display: inline-block;
    }
}
