.navPic{
    width: 200px;
    height: 200px;
    border-radius: 120px;
    border: 1px solid #aaa;
    margin: 10px;
}
.navBox{
    background-color: #eaeaea;
    min-width: 300px;
    padding-bottom: 50px;
    text-align: center;
    position: relative;
}
.navItem{
    display: block;
    padding: 5px;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    font-family: "Courier New";
    transition: 0.5s;

}
.navIconItem{
    text-decoration: none;
    color: #333333;
}
.navIconItem:hover{
    color: #0066d2;
}
.navItem:hover{
     background-color: #ffffff;
}

.selected{
    background-color: #f5f5f5;
}
.navBottom{
    min-width: 100%;
    position: absolute;
    bottom: 10px;
    text-align: center;
    font-family: monospace;
}
.iconLabelMod{
    font-size: 13px;
    padding-left: 19px;
}
.navBox.open{
    transform: translate(0px, 0px);
}
@media only screen and (max-width: 760px) {
    .navBox{
        position: absolute;
        z-index: 2;
        height: 100vh;
        transform: translate(-300px, 0px);
        transition:transform 0.5s;
        padding-bottom: 0px;
    }
}
